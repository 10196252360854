import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import Modal from '../../../components/admin-panel/Modal';
import { BACKEND_URL } from '../../../components/web/config';

interface Subscriber {
  id: number;
  email: string;
  created_at: string;
}

const SubscribersPage: React.FC = () => {
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState<Subscriber | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/newsletter/subscribers`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setSubscribers(data);
      } catch (error) {
        console.error('Error fetching subscribers:', error);
      }
    };

    fetchSubscribers();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentSubscribers = subscribers.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(subscribers.length / recordsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const openModal = (subscriber: Subscriber) => {
    setSelectedSubscriber(subscriber);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl font-bold">Newsletter Subscribers</h1>
      <div className="mt-4">
        {currentSubscribers.length > 0 ? (
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="py-3 px-6">Subscriber Email</th>
                <th className="py-3 px-6">Subscribed At</th>
                <th className="py-3 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentSubscribers.map((subscriber) => (
                <tr key={subscriber.id} className="bg-white border-b dark:bg-slate-800 dark:border-gray-700">
                  <td className="py-4 px-6">{subscriber.email}</td>
                  <td className="py-4 px-6">{new Date(subscriber.created_at).toLocaleDateString()}</td>
                  <td className="py-4 px-6">
                    <button
                      onClick={() => openModal(subscriber)}
                      className="text-indigo-400 hover:text-indigo-500 px-3 py-1"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No subscribers found.</p>
        )}
      </div>

      <div className="flex items-center justify-center space-x-2 mt-4">
        <button
          onClick={() => handleChangePage(1)}
          className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
          disabled={currentPage === 1}
        >
          First
        </button>
        <button
          onClick={() => handleChangePage(currentPage - 1)}
          className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          onClick={() => handleChangePage(currentPage + 1)}
          className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
        <button
          onClick={() => handleChangePage(totalPages)}
          className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
          disabled={currentPage === totalPages}
        >
          Last
        </button>
      </div>

      {selectedSubscriber && (
        <Modal isOpen={isModalOpen} onClose={closeModal} title="Subscriber Details">
          <div>
            <p><strong>Email:</strong> {selectedSubscriber.email}</p>
            <p><strong>Subscribed At:</strong> {new Date(selectedSubscriber.created_at).toLocaleDateString()}</p>
          </div>
        </Modal>
      )}
    </AdminLayout>
  );
};

export default SubscribersPage;
