import React from 'react';
import NavBar from '../../components/web/NavBar';
import HeaderSection from '../../components/web/Header';
import HeroSection from '../../components/web/HeroSection';
import HeroImage from '../../images/hero-image.jpg';
import CarTypeSection from '../../components/web/CarTypeSection';
import FeaturedCarsSection from '../../components/web/FeaturedCarsSection';
import HomeAbout from '../../components/web/HomeAbout';
import ServicesWeOffer from '../../components/web/ServicesWeOffer';
import OurPartners from '../../components/web/OurPartners';
import FAQs from '../../components/web/FAQs';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';

const App: React.FC = () => {
  return (
    <div className="relative">
      <HeaderSection />
      <NavBar isHomepage={true} />
      <HeroSection 
        height="h-148"
        backgroundImage={HeroImage}
        title="Welcome to Auto Dragons"
        subTitle="Explore our services and find the best solutions for your needs."
        buttonText="Find Your Ride"
        motButtonText='MOT, Auto Repair & Maintenance'
      />
      <CarTypeSection />
      <FeaturedCarsSection />
      <HomeAbout />
      <ServicesWeOffer />
      <OurPartners />
      <NewsletterSubscription />
      <FAQs />
      <Footer />
    </div>
  );
};

export default App;
