import React, { useState } from 'react';
import BannerImage from '../../images/news-banner.jpg';  // Ensure the path is correct
import Modal from '../admin-panel/Modal';
import { BACKEND_URL } from './config';

const NewsletterSubscription = () => {
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);  // Modal visibility state
  const [modalMessage, setModalMessage] = useState('');  // Modal message

  const handleInputChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!email) {
      setModalMessage('Please enter a valid email address.');
      setIsModalOpen(true);  // Open modal with error message
      return;
    }

    try {
      const response = await fetch(`${BACKEND_URL}/api/newsletter/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setModalMessage(`Thank you for subscribing with: ${email}`);
      } else {
        const data = await response.json();
        setModalMessage(`Failed to subscribe: ${data.message}`);
      }
    } catch (error) {
      setModalMessage('An error occurred. Please try again later.');
    }

    setIsModalOpen(true);  // Open modal with the response message
    setEmail('');  // Clear input after submission
  };

  return (
    <section className="newsletter-section bg-cover bg-center py-16 px-6 lg:px-16 relative"
      style={{ backgroundImage: `url(${BannerImage})` }}>
      {/* Black Overlay */}
      <div className="absolute inset-0 bg-black opacity-70"></div>

      <div className="container mx-auto text-center relative">
        <div className="rounded-lg p-8">
          {/* Section Title */}
          <h2 className="text-3xl font-bold text-white mb-4">Stay Updated</h2>
          <p className="text-white mb-6">Subscribe to our newsletter for the latest car deals and news.</p>
          
          {/* Subscription Form */}
          <form onSubmit={handleSubmit} className="flex justify-center items-center">
            <input
              type="email"
              placeholder="Enter your email address"
              className="p-3 text-white outline-none bg-transparent border-t border-b border-l rounded-l-lg w-96"
              value={email}
              onChange={handleInputChange}
              required
            />
            <button
              type="submit"
              className="bg-orange-700 hover:bg-orange-600 text-white border border-orange-700 font-bold p-3 rounded-r-lg transition-colors duration-300"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>

      {/* Modal Component for displaying messages */}
      <Modal
        isOpen={isModalOpen}
        title="Newsletter Subscription"
        onClose={() => setIsModalOpen(false)}  // Close the modal
      >
        <p>{modalMessage}</p>
      </Modal>
    </section>
  );
};

export default NewsletterSubscription;
