import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import { BACKEND_URL } from '../../../components/web/config';

interface Inquiry {
  id: number;
  car_id: number;
  name: string;
  email: string;
  phone: string;
  message: string;
  status: string;
  created_at: string;
}

const Inquiries: React.FC = () => {
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  // Fetch inquiries when the component mounts
  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/inquiries`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setInquiries(data);
      } catch (error) {
        console.error('Error fetching inquiries:', error);
      }
    };

    fetchInquiries();
  }, []);

  // Calculate current records for pagination
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = inquiries.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(inquiries.length / recordsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  // Update status of an inquiry
  const updateStatus = async (id: number, newStatus: string) => {
    // Optimistic update: immediately update the UI before the API response
    setInquiries(inquiries.map(inquiry =>
      inquiry.id === id ? { ...inquiry, status: newStatus } : inquiry
    ));

    try {
      const response = await fetch(`${BACKEND_URL}/api/inquiries/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const updatedInquiry = await response.json();

      // Update the state again with the correct status, in case there were any discrepancies
      setInquiries(inquiries.map(inquiry =>
        inquiry.id === id ? { ...inquiry, status: updatedInquiry.status } : inquiry
      ));
    } catch (error) {
      console.error('Error updating status:', error);
      // Optionally, revert the status change in case of an error
    }
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl font-bold">Inquiries</h1>
      <div className="mt-4">
        {inquiries.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">Car ID</th>
                  <th scope="col" className="py-3 px-6">Full Name</th>
                  <th scope="col" className="py-3 px-6">Email</th>
                  <th scope="col" className="py-3 px-6">Phone</th>
                  <th scope="col" className="py-3 px-6">Message</th>
                  <th scope="col" className="py-3 px-6">Status</th>
                  <th scope="col" className="py-3 px-6">Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentRecords.map((inquiry) => (
                  <tr key={inquiry.id} className="bg-white border-b dark:bg-slate-800 dark:border-gray-700">
                    {/* Wrap the car_id link inside a <td> but don't use <a> directly wrapping <td> */}
                    <td className="py-4 px-6">
                      <a
                        href={`/cars/${inquiry.car_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-orange-500 cursor-pointer"
                      >
                        {inquiry.car_id}
                      </a>
                    </td>
                    <td className="py-4 px-6">{inquiry.name}</td>
                    <td className="py-4 px-6">{inquiry.email}</td>
                    <td className="py-4 px-6">{inquiry.phone}</td>
                    <td className="py-4 px-6">{inquiry.message}</td>
                    <td className="py-4 px-6">{inquiry.status}</td>
                    <td className="py-4 px-6">
                      {/* Actions to update the inquiry status */}
                      <button
                        onClick={() => updateStatus(inquiry.id, 'contacted')}
                        className="text-indigo-500 hover:text-indigo-900 px-3 py-1"
                        disabled={inquiry.status === 'completed'}
                      >
                        Contacted
                      </button>
                      <button
                        onClick={() => updateStatus(inquiry.id, 'completed')}
                        className="text-green-600 hover:text-green-900 px-3 py-1"
                        disabled={inquiry.status === 'completed'}
                      >
                        Completed
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex items-center justify-center space-x-2 mt-4">
              <button
                onClick={() => handleChangePage(1)}
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
                disabled={currentPage === 1}
              >
                First
              </button>
              <button
                onClick={() => handleChangePage(currentPage - 1)}
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`}
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button
                onClick={() => handleChangePage(currentPage + 1)}
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button
                onClick={() => handleChangePage(totalPages)}
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`}
                disabled={currentPage === totalPages}
              >
                Last
              </button>
            </div>
          </div>
        ) : (
          <p>No inquiries found.</p>
        )}
      </div>
    </AdminLayout>
  );
};

export default Inquiries;
