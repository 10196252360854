// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './pages/hooks/AuthContext';
import Register from './pages/admin-panel/auth/register';
import Login from './pages/admin-panel/auth/login';
import Dashboard from './pages/admin-panel/dashboard/dashboard';
import SellCar from './pages/admin-panel/dashboard/SellCar';
import Settings from './pages/admin-panel/dashboard/Settings';
import AuthWrapper from './components/AuthWrapper';
import CarBrand from './pages/admin-panel/dashboard/CarBrand';
import ViewCars from './pages/admin-panel/dashboard/ViewCars';
import CarDetail from './pages/admin-panel/dashboard/CarDetail';
import TestDriveQueries from './pages/admin-panel/dashboard/TestDriveQueries';
import FinanceQueries from './pages/admin-panel/dashboard/FinanceQueries';
import MotQueries from './pages/admin-panel/dashboard/MotQueries';

// Frontend
import Home from './pages/website/home';
import DetailCarPage from './pages/website/DetailCarPage';
import Listings from './pages/website/CarList';
import FinancePage from './pages/website/FinancePage';
import TestDrive from './pages/website/TestDrive';
import Contact from './pages/website/Contact';
import Mot from './pages/website/mot';
import SubscribersPage from './pages/admin-panel/dashboard/Subscribers';
import Inquiries from './pages/admin-panel/dashboard/Inquiries';

const App: React.FC = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cars/:id" element={<DetailCarPage />} /> 
          <Route path="/listing" element={<Listings />} /> 
          <Route path="/contact-us" element={<Contact />} /> 
          <Route path="/mot" element={<Mot />} /> 
          <Route path="/finance/:id" element={<FinancePage />} />
          <Route path="/testdrive/:id" element={<TestDrive />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <AuthWrapper>
                <Dashboard />
              </AuthWrapper>
            }
          />
          <Route
            path="/sell-car/add"
            element={
              <AuthWrapper>
                <SellCar />
              </AuthWrapper>
            }
          />
          <Route
            path="/sell-car/view"
            element={
              <AuthWrapper>
                <ViewCars />
              </AuthWrapper>
            }
          />
          <Route
            path="/settings"
            element={
              <AuthWrapper>
                <Settings />
              </AuthWrapper>
            }
          />
          <Route
            path="/car-brands"
            element={
              <AuthWrapper>
                <CarBrand />
              </AuthWrapper>
            }
          />
           <Route
            path="/finance-queries"
            element={
              <AuthWrapper>
                <FinanceQueries />
              </AuthWrapper>
            }
          />
           <Route
            path="/test-drive-queries"
            element={
              <AuthWrapper>
                <TestDriveQueries />
              </AuthWrapper>
            }
          />
          <Route
            path="/mot-queries"
            element={
              <AuthWrapper>
                <MotQueries />
              </AuthWrapper>
            }
          />
          <Route
            path="/inquiries"
            element={
              <AuthWrapper>
                <Inquiries />
              </AuthWrapper>
            }
          />
          <Route
            path="/subscriber"
            element={
              <AuthWrapper>
                <SubscribersPage />
              </AuthWrapper>
            }
          />
          <Route
            path="/sell-car/details/:id"
            element={
              <AuthWrapper>
                <CarDetail />
              </AuthWrapper>
            }
          />
        </Routes>
      </AuthProvider>
    </Router>
  );
};

export default App;
