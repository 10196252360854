import React, { useEffect, useState } from 'react';
import { BACKEND_URL, STATIC_URL } from './config';

type SubItem = {
  name: string;
  href: string;
  imageUrl?: string;
};

type NavItem = {
  name: string;
  href?: string;
  subItems?: SubItem[];
};

interface NavBarProps {
  isHomepage?: boolean; 
}

const NavBar: React.FC<NavBarProps> = ({ isHomepage = false }) => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarData, setNavbarData] = useState<Record<string, NavItem>>({
    "Home": { name: "Home", href: "/" },
    "Stock": { name: "Stock", subItems: [] },
    "Mot": { name: "MOT", href: "/mot" },
    "Contact Us": { name: "Contact Us", href: "/contact-us" },
  });

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/brands/list`);
        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        const brands = result.brands;

        if (!Array.isArray(brands)) {
          console.error('Expected an array of brands, received:', brands);
          return;
        }

        setNavbarData(prev => ({
          ...prev,
          "Stock": {
            name: "Stock",
            subItems: brands.map((brand) => ({
              name: `Used ${brand.name}`,
              href: `/listing?make=${encodeURIComponent(brand.name)}`,
              imageUrl: `${STATIC_URL}${brand.image_url}`
            }))
          }
        }));
      } catch (error) {
        console.error('Failed to fetch brands:', error);
      }
    };

    fetchBrands();
  }, []);

  const handleParentClick = (key: string) => {
    // For mobile: toggle dropdown if it has subItems
    if (navbarData[key].subItems && navbarData[key].subItems!.length > 0) {
      setActiveDropdown(activeDropdown === key ? null : key);
    } else {
      // If no subItems, close mobile menu
      setIsMobileMenuOpen(false);
    }
  };

  return (
    <div className={`absolute ${isHomepage ? 'top-32' : 'top-0'} border-t border-b border-gray-500 left-0 right-0 z-20 bg-slate-800 text-white`}>
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          {/* Mobile menu button */}
          <button
            className="md:hidden block text-gray-300 hover:text-white focus:text-white focus:outline-none"
            onClick={() => {
              setIsMobileMenuOpen(!isMobileMenuOpen);
              setActiveDropdown(null);
            }}
            aria-label="Toggle Navigation"
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" strokeWidth={2} viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-4">
            {Object.entries(navbarData).map(([key, item]) => (
              <div 
                key={key} 
                className="relative group" 
                onMouseLeave={() => setActiveDropdown(null)}
              >
                {item.subItems && item.subItems.length > 0 ? (
                  <>
                    <button 
                      className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                      onMouseEnter={() => setActiveDropdown(key)}
                      onClick={() => setActiveDropdown(activeDropdown === key ? null : key)}
                    >
                      {item.name}
                    </button>
                    {activeDropdown === key && (
                      <div className="absolute left-0 mt-1 w-48 shadow-lg bg-white text-black z-50">
                        {item.subItems.map((subItem, index) => (
                          <a 
                            key={index} 
                            href={subItem.href} 
                            className="block px-4 py-2 text-sm hover:bg-gray-100 flex items-center"
                          >
                            {subItem.imageUrl && <img src={subItem.imageUrl} alt={subItem.name} className="w-8 h-8 object-contain mr-2" />}
                            {subItem.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <a 
                    href={item.href} 
                    className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium flex items-center"
                  >
                    {item.name}
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile Menu (Slide out) */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-slate-800 border-t border-gray-500">
          <nav className="px-4 pt-2 pb-4 space-y-1">
            {Object.entries(navbarData).map(([key, item]) => (
              <div key={key}>
                {item.subItems && item.subItems.length > 0 ? (
                  <>
                    <button
                      className="w-full text-left flex items-center justify-between hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleParentClick(key)}
                    >
                      <span>{item.name}</span>
                      <svg
                        className={`w-5 h-5 transform transition-transform duration-200 ${activeDropdown === key ? 'rotate-180' : ''}`}
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    {activeDropdown === key && (
                      <div className="ml-4 border-l border-gray-600 pl-4 mt-2 space-y-2">
                   {item.subItems.map((subItem, index) => (
                          <a 
                            key={index} 
                            href={subItem.href} 
                            className="block px-4 py-2 text-sm hover:bg-gray-700 flex items-center"
                          >
                            {subItem.imageUrl && <img src={subItem.imageUrl} alt={subItem.name} className="w-8 h-8 object-contain mr-2" />}
                            {subItem.name}
                          </a>
                        ))}
                      </div>
                    )}
                  </>
                ) : (
                  <a
                    href={item.href}
                    className="block hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={() => setIsMobileMenuOpen(false)}
                  >
                    {item.name}
                  </a>
                )}
              </div>
            ))}
          </nav>
        </div>
      )}
    </div>
  );
};

export default NavBar;
