// AdminColors.tsx

export type AdminColorsType = {
  primary: string;
  secondary: string;
  tertiary: string;
  primaryText: string;
  secondaryText: string;
  border: string;
  focusBorder: string;
  highlight: string;
  button: string;
};

// Updated colors for a more aesthetic look
const AdminColors: AdminColorsType = {
  primary: 'bg-slate-900', // Dark slate for the main background
  secondary: 'bg-slate-800', // Slightly lighter slate for secondary elements
  tertiary: 'bg-slate-700', // Even lighter slate for tertiary elements
  primaryText: 'text-gray-100', // Soft white for primary text
  secondaryText: 'text-gray-400', // Muted gray for secondary text
  border: 'border-gray-700', // Subtle border for a clean look
  focusBorder: 'focus:border-orange-500', // Indigo for focus states
  highlight: 'text-orange-400', // Highlighted text in indigo
  button: 'bg-orange-600 hover:bg-orange-700 text-white', // Stylish indigo for buttons with hover effect
};

export default AdminColors;
