import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AdminColors from '../../components/admin-panel/AdminColors';
import AdminTable from '../../components/admin-panel/Table';
import Card from '../../components/admin-panel/Card';
import InputField from '../../components/admin-panel/InputField';
import Button from '../../components/admin-panel/Button';
import Notification from '../../components/admin-panel/Notification';
import NavBar from '../../components/web/NavBar';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import { FaCar, FaPalette, FaCalendarAlt, FaTag, FaCheckCircle, FaGasPump, FaCogs, FaCloud, FaWrench, FaWeightHanging, FaFileAlt, FaGlobe, FaCheck, FaRobot, FaUser, FaEnvelope, FaPhone, FaComment } from 'react-icons/fa';
import { BACKEND_URL, STATIC_URL } from '../../components/web/config';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  fuelType: string;
  taxStatus: string;
  engineCapacity: string;
  co2Emissions: string;
  markedForExport: boolean;
  typeApproval: string;
  wheelPlan: string;
  revenueWeight: number;
  realDrivingEmissions: string;
  dateOfLastV5CIssued: Date;
  automatedVehicle: boolean;
  price: number;
  mileage: number;
  isFeatured: boolean;
  category: string;
  status: string;
  description?: string;
  submitted_by: number;
  listed: boolean;
  images: string[];
}

// Define the notification state with a strict type
interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

const CarDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [car, setCar] = useState<Car | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({ show: false, message: '', type: 'error' });
  const [error, setError] = useState('');

  // Form state
  const [inquiry, setInquiry] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setInquiry((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmitInquiry = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/inquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...inquiry, carId: id }),
      });

      if (response.ok) {
        setNotification({ show: true, message: 'Inquiry sent successfully!', type: 'success' });
        setInquiry({ name: '', email: '', phone: '', message: '' }); // Reset form
      } else {
        const errorData = await response.json();
        setNotification({ show: true, message: errorData.message || 'Failed to send inquiry.', type: 'error' });
      }
    } catch (error) {
      console.error('Error sending inquiry:', error);
      setNotification({ show: true, message: 'An error occurred. Please try again later.', type: 'error' });
    }
  };

  useEffect(() => {
    fetchCarDetails();
  }, [id]);

  const fetchCarDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details/${id}`);
      if (response.ok) {
        const data = await response.json();
        setCar({
          ...data.car,
          fuelType: data.car.fuel_type,
          isFeatured: data.car.isFeatured ?? false,
          taxStatus: data.car.tax_status,
          engineCapacity: data.car.engine_capacity,
          co2Emissions: data.car.co2_emissions,
          markedForExport: data.car.marked_for_export,
          typeApproval: data.car.type_approval,
          wheelPlan: data.car.wheelplan,
          revenueWeight: data.car.revenue_weight,
          realDrivingEmissions: data.car.real_driving_emissions,
          dateOfLastV5CIssued: data.car.date_of_last_v5c_issued,
          automatedVehicle: data.car.automated_vehicle,
        });
      
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch car details.');
      }
    } catch (err) {
      setError('An error occurred while fetching car details.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Function for deleting a car
  const handleDeleteCar = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        navigate('/sell-car/view'); // Navigate back to the dashboard on success
      } else {
        throw new Error('Failed to delete the car.');
      }
    } catch (error) {
      console.error('Error deleting car:', error);
    }
  };

  if (loading) return <p className="text-blue-500 mt-4">Loading car details...</p>;
  if (error) return <p className="text-red-500 mt-4">{error}</p>;
  if (!car) return <p className="text-red-500 mt-4">No car details available.</p>;

  const sliderSettings = {
    dots: true,
    infinite: car.images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

return (
<div className='bg-gray-900'>
  <NavBar />
  {loading && <p className="text-gray-500 mt-4">Loading car details...</p>}
  {notification.show && <Notification message={notification.message} type={notification.type} />}
  {!loading && car && (
    <div className="container mx-auto px-4 pt-28">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-8">
        {/* Car Images */}
        <div className="md:col-span-2">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">{car.make || 'Unknown Make'} Pictures</h1>
          {car.images && car.images.length > 0 ? (
            <Slider {...sliderSettings}>
              {car.images.map((image, index) => (
                <div key={index} className="h-96 flex justify-center items-center">
                  <img
                    src={`${STATIC_URL}${image}`}
                    alt={`Car Image ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p className={`text-sm ${AdminColors.secondaryText}`}>
              No images available for this car.
            </p>
          )}
        </div>

        {/* Basic Car Details */}
        <div className="md:col-span-3">
          <h1 className="text-2xl text-gray-300 font-bold mb-4">Basic Car Details</h1>
          <AdminTable
            columns={[
              { key: 'field', header: 'Field' },
              { key: 'value', header: 'Value' },
            ]}
            data={[
              { 
                field: (
                  <div className="flex items-center">
                    <FaCar className="mr-3 text-2xl text-slate-300" />
                    Registration Number
                  </div>
                ),
                value: car.registration_number 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaTag className="mr-3 text-2xl text-slate-300" />
                    Make
                  </div>
                ),
                value: car.make 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCalendarAlt className="mr-3 text-2xl text-slate-300" />
                    Year
                  </div>
                ),
                value: car.year 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaPalette className="mr-3 text-2xl text-slate-300" />
                    Color
                  </div>
                ),
                value: car.color 
              },
              { 
                field: (
                  <div className="flex items-center">
                    <FaCheckCircle className="mr-3 text-2xl text-slate-300" />
                    Status
                  </div>
                ),
                value: car.status 
              },
            ]}
            headerBgColor="secondary"
            tableBgColor="tertiary"
            borderColor="border"
            textColor="primaryText"
            className="mb-4"
          />
        </div>
      </div>

      {/* Technical Specifications */}
      <div className="mb-8">
        <h2 className="text-xl text-gray-300 font-bold mb-4">Technical Specifications</h2>
        <AdminTable
          columns={[
            { key: 'field', header: 'Field' },
            { key: 'value', header: 'Value' },
          ]}
          data={[
            {
              field: (
                <div className="flex items-center">
                  <FaGasPump className="mr-3 text-2xl text-slate-300" />
                  Fuel Type
                </div>
              ),
              value: car.fuelType,
            },
            {
              field: (
                <div className="flex items-center">
                  <FaCogs className="mr-3 text-2xl text-slate-300" />
                  Engine Capacity
                </div>
              ),
              value: `${car.engineCapacity} cc`,
            },
            {
              field: (
                <div className="flex items-center">
                  <FaCloud className="mr-3 text-2xl text-2xl text-slate-300" />
                  CO2 Emissions
                </div>
              ),
              value: `${car.co2Emissions} g/km`,
            },
            {
              field: (
                <div className="flex items-center">
                  <FaWrench className="mr-3 text-2xl text-slate-300" />
                  Wheel Plan
                </div>
              ),
              value: car.wheelPlan,
            },
            {
              field: (
                <div className="flex items-center">
                  <FaWeightHanging className="mr-3 text-2xl text-slate-300" />
                  Revenue Weight
                </div>
              ),
              value: `${car.revenueWeight} kg`,
            },
          ]}
          headerBgColor="secondary"
          tableBgColor="tertiary"
          borderColor="border"
          textColor="primaryText"
        />
      </div>

      {/* Regulatory Information */}
      <div className="mb-8">
        <h2 className="text-xl text-gray-300 font-bold mb-4">Regulatory Information</h2>
        <AdminTable
        columns={[
          { key: 'field', header: 'Field' },
          { key: 'value', header: 'Value' },
        ]}
        data={[
          {
            field: (
              <div className="flex items-center">
                <FaFileAlt className="mr-3 text-2xl text-slate-300" />
                Tax Status
              </div>
            ),
            value: car.taxStatus,
          },
          {
            field: (
              <div className="flex items-center">
                <FaGlobe className="mr-3 text-2xl text-slate-300" />
                Marked for Export
              </div>
            ),
            value: car.markedForExport ? 'Yes' : 'No',
          },
          {
            field: (
              <div className="flex items-center">
                <FaCheck className="mr-3 text-2xl text-slate-300" />
                Type Approval
              </div>
            ),
            value: car.typeApproval,
          },
          {
            field: (
              <div className="flex items-center">
                <FaCalendarAlt className="mr-3 text-2xl text-slate-300" />
                Date of Last V5C Issued
              </div>
            ),
            value: car.dateOfLastV5CIssued,
          },
          {
            field: (
              <div className="flex items-center">
                <FaRobot className="mr-3 text-2xl text-slate-300" />
                Automated Vehicle
              </div>
            ),
            value: car.automatedVehicle ? 'Yes' : 'No',
          },
        ]}
        headerBgColor="secondary"
        tableBgColor="tertiary"
        borderColor="border"
        textColor="primaryText"
      />
      </div>
        <div className="mb-8">
        <h2 className='text-lg text-gray-300 mb-4 font-bold'>Inquire About This Car</h2>
          <div className='bg-slate-700 px-6 py-8 rounded-lg'>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex items-center">
                <FaUser className="mr-3 text-xl text-slate-300" />
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={inquiry.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
                />
              </div>
              <div className="flex items-center">
                <FaEnvelope className="mr-3 text-xl text-slate-300" />
                <input
                  type="email"
                  name="email"
                  placeholder="Your Email"
                  value={inquiry.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
                />
              </div>
              <div className="flex items-center">
                <FaPhone className="mr-3 text-xl text-slate-300" />
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={inquiry.phone}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
                />
              </div>
              <div className="flex items-center">
                <FaComment className="mr-3 text-xl text-slate-300" />
                <textarea
                  name="message"
                  placeholder="Your Message"
                  value={inquiry.message}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-slate-500 text-sm bg-slate-800 text-slate-300 rounded"
                  rows={4}
                ></textarea>
              </div>
            </div>
            <Button size="small" onClick={handleSubmitInquiry} className="mt-4">
              Submit Inquiry
            </Button>
          </div>
        </div>
      </div>
    )}
<NewsletterSubscription />
<Footer />
</div>
);

};

export default CarDetail;
