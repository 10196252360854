import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Swiper as SwiperClass } from 'swiper';
import { useNavigate } from 'react-router-dom';

// Import images
import SedanIcon from '../../images/icons/sedan.png';
import SUVIcon from '../../images/icons/suv.png';
import HatchbackIcon from '../../images/icons/hatchback.png';
import CoupeIcon from '../../images/icons/coupe.png';
import ConvertibleIcon from '../../images/icons/convertible.png';
import PickupTruckIcon from '../../images/icons/pickup.png';
import MinivanIcon from '../../images/icons/minivan.png';
import Background from '../../images/header.jpg'; // Import background image

const carTypes = [
  { name: 'Sedan', icon: SedanIcon },
  { name: 'SUV', icon: SUVIcon },
  { name: 'Hatchback', icon: HatchbackIcon },
  { name: 'Coupe', icon: CoupeIcon },
  { name: 'Convertible', icon: ConvertibleIcon },
  { name: 'Pickup Truck', icon: PickupTruckIcon },
  { name: 'Minivan', icon: MinivanIcon },
];

const CarTypeSection = () => {

  const navigate = useNavigate();

  const handleCategoryClick = (category: string) => {
    navigate(`/listing?category=${encodeURIComponent(category)}`);
  };


  return (
    <div
      className="relative h-64 flex items-center justify-center px-6 lg:px-16 xl:px-24 overflow-hidden"
      style={{
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute inset-0 bg-orange-800 bg-opacity-80"></div>
      <Swiper
        spaceBetween={5}
        slidesPerView={4}
        onSwiper={(swiper: SwiperClass) => console.log(swiper)}
        breakpoints={{
          640: { slidesPerView: 3, spaceBetween: 25 },
          768: { slidesPerView: 4, spaceBetween: 20 },
          1024: { slidesPerView: 7, spaceBetween: 25 },
        }}
      >
        {carTypes.map((car, index) => (
          <SwiperSlide key={index}>
            <div className="flex pt-5 pb-5 flex-col items-center text-white group relative cursor-pointer" onClick={() => handleCategoryClick(car.name)}>
              {/* Circle with border waves */}
              <div className="relative flex items-center justify-center">
                {/* Circle with icon */}
                <div className="relative p-3 rounded-full bg-orange-100 hover:bg-orange-700 transition-colors duration-300 flex justify-center items-center border-4 border-gray-900 z-10">
                  <img src={car.icon} alt={car.name} className="h-12 w-12" />
                </div>
                {/* Border wave animations */}
                <div className="absolute h-20 w-20 rounded-full border-4 border-white-500 animate-wave z-0" />
              </div>
              {/* Text */}
              <span className="mt-4 text-sm">{car.name}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarTypeSection;
