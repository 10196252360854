import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AdminColors from '../../components/admin-panel/AdminColors';
import Button from '../../components/admin-panel/Button';
import NavBar from '../../components/web/NavBar';
import Footer from '../../components/web/Footer';
import NewsletterSubscription from '../../components/web/NewsletterSubscription';
import { FaCar, FaCalendarAlt, FaTachometerAlt, FaGasPump, FaPoundSign, FaBan, FaCheckCircle, FaDollarSign } from 'react-icons/fa';
import { PiEngineFill } from "react-icons/pi";
import { TbCategoryFilled } from "react-icons/tb";
import { IoSearchSharp } from "react-icons/io5";
import HeroSection from '../../components/web/HeroSection';
import HeroImage from '../../images/listing.jpg'
import { BACKEND_URL, STATIC_URL } from '../../components/web/config';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  price: number;
  mileage: number;
  fuel_type: string;
  category: string;
  engine_capacity: number;
  status: string;
  images: string[];
}

const Listings: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    make: '',
    category: '',
    minPrice: '',
    maxPrice: '',
    year: '',
    });
  const [cars, setCars] = useState<Car[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleCarClick = (carId: number) => {
    navigate(`/cars/${carId}`); 
  };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const makeFromUrl = params.get('make');
        const categoryFromUrl = params.get('category');
        const updatedFilters = {
            make: makeFromUrl || '',
            category: categoryFromUrl || '',
            minPrice: filters.minPrice,
            maxPrice: filters.maxPrice,
            year: filters.year,
        };
        setFilters(updatedFilters);
        fetchFilteredCars(updatedFilters);
    }, [location.search]);  
    

    const fetchFilteredCars = async (filtersToApply: any) => {
        setLoading(true);
        setError('');
        try {
            const queryParams = new URLSearchParams(filtersToApply).toString();
            const response = await fetch(`${BACKEND_URL}/api/cars/filtered-cars?${queryParams}`);
            const data = await response.json();
            if (response.ok) {
                setCars(data || []);
            } else {
                setError(data.message || 'Failed to fetch cars.');
            }
        } catch (err) {
            setError('An error occurred while fetching cars.');
        } finally {
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchFilteredCars(filters);
    }, [filters]);
    
  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const carCategories = [
    { label: "Sedan", value: "Sedan" },
    { label: "SUV", value: "SUV" },
    { label: "Hatchback", value: "Hatchback" },
    { label: "Coupe", value: "Coupe" },
    { label: "Convertible", value: "Convertible" },
    { label: "Pickup Truck", value: "Pickup Truck" },
    { label: "Minivan", value: "Minivan" },
];

  return (
    <div className='bg-gray-900'>
        <NavBar />
    <HeroSection 
        height="h-120"
        backgroundImage={HeroImage}
        title="Find Your Vehicle"
        subTitle="Get your vehicle at affordable rates."
    />
      {loading && <p className="text-blue-500 mt-4">Loading cars...</p>}
      {error && <p className="text-red-500 mt-4">{error}</p>}
        <div className="filter-controls pt-12 p-4 bg-gray-900 text-white rounded mb-6 px-16">
        <h2 className="text-xl text-gray-300 font-bold mb-4">Find Your Car</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <input
                    type="text"
                    placeholder="Make"
                    value={filters.make}
                    onChange={(e) => setFilters({ ...filters, make: e.target.value })}
                    className="p-2 rounded bg-slate-600 border-none outline-none"
                />
                <select
                    value={filters.category}
                    onChange={(e) => setFilters({ ...filters, category: e.target.value })}
                    className="p-2 rounded bg-slate-600 border-none outline-none"
                >
                    <option value="">All Categories</option>
                    {carCategories.map((cat) => (
                        <option key={cat.value} value={cat.value}>{cat.label}</option>
                    ))}
                </select>
                <input
                    type="number"
                    placeholder="Min Price"
                    value={filters.minPrice}
                    onChange={(e) => setFilters({ ...filters, minPrice: e.target.value })}
                    className="p-2 rounded bg-slate-600 border-none outline-none"
                />
                <input
                    type="number"
                    placeholder="Max Price"
                    value={filters.maxPrice}
                    onChange={(e) => setFilters({ ...filters, maxPrice: e.target.value })}
                    className="p-2 rounded bg-slate-600 border-none outline-none"
                />
                <input
                    type="number"
                    placeholder="Year"
                    value={filters.year}
                    onChange={(e) => setFilters({ ...filters, year: e.target.value })}
                    className="p-2 rounded bg-slate-600 border-none outline-none"
                />
            </div>
            {/* <button
                onClick={fetchFilteredCars}
                className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded text-white"
            >
                Apply Filters
            </button> */}
        </div>

        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-16 pt-5 mb-16">
        {!loading && cars.length === 0 && !error && (
        <p className={`mt-4 ${AdminColors.secondaryText}`}>No cars available.</p>
      )}
        {cars.map((car) => (
            <div key={car.id} className={`p-4 rounded border ${AdminColors.border} ${AdminColors.secondary}`} >
                {car.images && car.images.length > 1 ? (
                    <Slider {...sliderSettings} className="mb-4 relative">
                    {car.images.map((image, index) => (
                        <div key={index} className="w-full h-80 flex justify-center items-center relative">
                        <img
                            src={`${STATIC_URL}${image}`}
                            alt={`Car Image ${index + 1}`}
                            className="w-full h-full object-cover rounded"
                        />
                            <div className={`absolute bottom-0 right-0 px-3 py-1 rounded text-sm font-bold ${car.status === 'sold' ? 'bg-red-500' : 'bg-green-500'} text-white flex items-center`}>
                                {car.status === 'sold' ? <><FaBan className="mr-2" /> SOLD</> : <><FaCheckCircle className="mr-2" /> AVAILABLE</>}
                            </div>
                        </div>
                    ))}
                    </Slider>
                ) : car.images && car.images.length === 1 ? (
                    <div className="w-full h-80 flex justify-center items-center mb-4 relative">
                    <img
                        src={`${STATIC_URL}${car.images[0]}`}
                        alt="Car Image"
                        className="w-full h-full object-cover rounded"
                    />
                        <div className={`absolute bottom-0 right-0 px-3 py-1 rounded text-sm font-bold ${car.status === 'sold' ? 'bg-red-500' : 'bg-green-500'} text-white flex items-center`}>
                            {car.status === 'sold' ? <><FaBan className="mr-2" /> SOLD</> : <><FaCheckCircle className="mr-2" /> AVAILABLE</>}
                        </div>
                    </div>
                ) : (
                    <p className={`text-sm ${AdminColors.secondaryText} mb-4`}>
                    No images available for this car.
                    </p>
                )}
            <div className="flex justify-between items-center border-b border-gray-700 pb-2 mb-2">
                <h2 className="text-xl text-slate-300 font-bold">{car.make || 'Unknown Make'}</h2>
                <div className="flex items-center bg-slate-300 px-3 rounded">
                    <FaPoundSign className="text-xl text-slate-800" />
                    <span className="text-2xl text-slate-800 rounded font-bold">{car.price}</span>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4 text-sm text-slate-300">
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaCar className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.registration_number}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaCalendarAlt className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.year}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <TbCategoryFilled className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.category}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaTachometerAlt className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.mileage} miles</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaGasPump className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.fuel_type || 'N/A'}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <PiEngineFill className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.engine_capacity || 'N/A'}</span>
                </div>
            </div>
            <div className="flex gap-2 mb-2">
                <Button
                    className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center bg-sky-700 hover:bg-sky-800`}
                    onClick={() => handleCarClick(car.id)}
                >
                    <IoSearchSharp className="mr-1" />View
                </Button>
                <Button
                    className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center bg-teal-700 hover:bg-teal-800`}
                    onClick={() => navigate(`/testdrive/${car.id}`)}
                >
                    <FaCar className="mr-1" />Test Drive
                </Button>
            </div>
            <Button
                size="small"
                className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center border border-slate-400 bg-transparent hover:bg-slate-600`}
                onClick={() => navigate(`/finance/${car.id}`)}
            >
                <FaDollarSign className="mr-1" />Finance
            </Button>
          </div>
        ))}
        </div>
        <NewsletterSubscription />
        <Footer />
    </div>
  );
};

export default Listings;
