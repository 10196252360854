import React from 'react';
import { FaFacebook, FaTiktok } from 'react-icons/fa';
import vravenz from '../../images/vravenz.png'

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white pt-8">
      <div className="container mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Quick Links */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">Quick Links</h5>
          <ul className="space-y-2 text-gray-400">
            <li><a href="/" className="hover:text-orange-500 transition-colors">Home</a></li>
            <li><a href="/listing" className="hover:text-orange-500 transition-colors">Find your Ride</a></li>
            <li><a href="/mot" className="hover:text-orange-500 transition-colors">MOT</a></li>
            <li><a href="/contact-us" className="hover:text-orange-500 transition-colors">Contact Us</a></li>
          </ul>
        </div>

        {/* Social Media Links */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">Follow Us</h5>
          <div className="flex space-x-4 text-gray-400">
              <a href="https://www.facebook.com/people/Auto-Dragons/61569126128897/" target="_blank" rel="noopener noreferrer" className="hover:text-blue-600"><FaFacebook size={20} /></a>
              <a href="https://www.tiktok.com/@autodragons" target="_blank" rel="noopener noreferrer" className="hover:text-blue-300"><FaTiktok size={20} /></a>
          </div>
        </div>

        {/* About Us Section */}
        <div>
          <h5 className="text-lg text-gray-300 font-bold mb-3">About Us</h5>
          <p className="text-sm text-gray-400 mb-3">
          At <span className='text-orange-600'>AutoDragons</span>, we’re more than just a car repair and maintenance service—we also specialize in buying and selling high-quality vehicles. Whether you're looking to upgrade your current ride or sell your car for a fair price, we are here to guide you every step of the way.
          </p>
        </div>
      </div>

      {/* Copyright */}
      <div className="text-center text-gray-400 mt-8 text-sm bg-slate-800 py-4">
        © {new Date().getFullYear()} <span className='text-orange-700'>Dragon Autos</span>. All Rights Reserved. Powered by <a href="https://vravenz.com" className='text-gray-100'><img src={vravenz} alt="Logo" className="inline-block h-4 mb-1" /> vravenz</a>
      </div>
    </footer>
  );
};

export default Footer;
