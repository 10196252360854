import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import react-slick for the slider
import 'slick-carousel/slick/slick.css'; // Slick styles
import 'slick-carousel/slick/slick-theme.css'; // Slick theme styles
import { useAuth } from '../../hooks/AuthContext';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import AdminColors from '../../../components/admin-panel/AdminColors';
import Button from '../../../components/admin-panel/Button';
import { BACKEND_URL, STATIC_URL } from '../../../components/web/config';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  price: number;
  submitted_by: number;
  images: string[]; // Array to hold image URLs
}

const ViewCars: React.FC = () => {
  const { user } = useAuth();
  const [cars, setCars] = useState<Car[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      setError('');
      try {
        const response = await fetch(`${BACKEND_URL}/api/sell-cars/list`);
        if (response.ok) {
          const data = await response.json();
          setCars(data.cars || []);
        } else {
          const errorData = await response.json();
          setError(errorData.message || 'Failed to fetch cars.');
        }
      } catch (err) {
        setError('An error occurred while fetching cars.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <AdminLayout>
      <h1 className="text-2xl font-bold">View Cars</h1>
      {loading && <p className="text-blue-500 mt-4">Loading cars...</p>}
      {error && <p className="text-red-500 mt-4">{error}</p>}
      {!loading && cars.length === 0 && !error && (
        <p className={`mt-4 ${AdminColors.secondaryText}`}>No cars available.</p>
      )}
<div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
  {cars.map((car) => (
    <div
      key={car.id}
      className={`p-4 rounded border ${AdminColors.border} ${AdminColors.secondary}`}
    >
      <h2 className="text-lg font-bold mb-2">{car.make || 'Unknown Make'}</h2>
      {car.images && car.images.length > 1 ? (
        <Slider {...sliderSettings} className="mb-4">
          {car.images.map((image, index) => (
            <div key={index} className="w-full h-80 flex justify-center items-center">
              <img
                src={`${STATIC_URL}${image}`}
                alt={`Car Image ${index + 1}`}
                className="w-full h-full object-cover rounded"
              />
            </div>
          ))}
        </Slider>
      ) : car.images && car.images.length === 1 ? (
        <div className="w-full h-80 flex justify-center items-center mb-4">
          <img
            src={`${STATIC_URL}${car.images[0]}`}
            alt="Car Image"
            className="w-full h-full object-cover rounded"
          />
        </div>
      ) : (
        <p className={`text-sm ${AdminColors.secondaryText} mb-4`}>
          No images available for this car.
        </p>
      )}
      <ul className="text-sm mb-4">
        <li>
          <strong>Registration Number:</strong> {car.registration_number}
        </li>
        <li>
          <strong>Year:</strong> {car.year}
        </li>
        <li>
          <strong>Price:</strong> {car.price}
        </li>
        <li>
          <strong>Color:</strong> {car.color}
        </li>
      </ul>
      <Button
        size="small"
        className={`${AdminColors.button} ${AdminColors.primaryText}`}
        onClick={() => (window.location.href = `/sell-car/details/${car.id}`)}
      >
      View Details
    </Button>
    </div>
  ))}
</div>

    </AdminLayout>
  );
};

export default ViewCars;
