import React, { useState } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const faqData = [
  {
    question: "What financing options do you offer?",
    answer:
      "We offer flexible financing options with low-interest rates, tailored to suit your budget. Contact our sales team for more details.",
  },
  {
    question: "What is your return policy?",
    answer:
      "You can return the car within 7 days of purchase if it meets our return conditions. Please refer to our return policy for more information.",
  },
  {
    question: "Do you provide a warranty with your cars?",
    answer:
      "Yes, all our cars come with a comprehensive warranty to ensure peace of mind. Warranty duration depends on the vehicle type.",
  },
  {
    question: "Can I trade in my old car?",
    answer:
      "Yes, we offer trade-in services. Bring your car to our showroom, and our experts will provide a competitive valuation.",
  },
];

const FAQs = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faqs-section bg-gray-800 py-16 px-6 lg:px-16">
      <div className="container mx-auto text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-gray-300 mb-4">FAQs</h2>
        <p className="text-gray-500 mb-10">
          Find answers to the most common questions about our services.
        </p>

        {/* FAQ Items */}
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div
              key={index}
              className="faq-item bg-gray-900 shadow-lg rounded-lg p-4"
            >
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <h3 className="text-lg font-semibold text-gray-400">
                  {faq.question}
                </h3>
                {openIndex === index ? (
                  <FiChevronUp className="text-gray-500 text-xl" />
                ) : (
                  <FiChevronDown className="text-gray-500 text-xl" />
                )}
              </div>
              {openIndex === index && (
                <div className="text-left">
                <p className="text-gray-500 mt-3">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
