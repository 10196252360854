import React from 'react';
import AdminColors from './AdminColors';

interface Option {
  value: string | number | boolean;
  label: string;
}

interface InputFieldProps {
  label?: string;
  type: 'text' | 'number' | 'select' | 'checkbox' | 'textarea' | 'date' | 'email' | 'tel';
  name: string;
  value: string | number | boolean | undefined;
  checked?: boolean;
  options?: Option[];
  placeholder?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
  className?: string;
  required?: boolean;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  value,
  checked = false,
  options = [],
  placeholder,
  onChange,
  className = '',
  required = false,
}) => {
  const renderInput = () => {
    switch (type) {
      case 'select':
        return (
          <select
            id={name}
            name={name}
            value={String(value)}
            onChange={onChange}
            className={`w-full p-2 border ${AdminColors.border} rounded ${AdminColors.primaryText} bg-transparent focus:${AdminColors.focusBorder}`}
            required={required}
          >
            <option value="" disabled>{placeholder || 'Select an option'}</option>
            {options.map(option => (
              <option key={String(option.value)} value={String(option.value)}>
                {option.label}
              </option>
            ))}
          </select>
        );
      case 'textarea':
        return (
          <textarea
            id={name}
            name={name}
            value={String(value)}
            onChange={onChange}
            placeholder={placeholder}
            className={`w-full p-2 ${AdminColors.border} resize-none border rounded ${AdminColors.primaryText} bg-transparent focus:${AdminColors.focusBorder}`}
            required={required}
            rows={5}
          />
        );
      case 'checkbox':
        return (
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              id={name}
              name={name}
              checked={Boolean(value)}
              onChange={onChange}
              className={`h-5 w-5 ${AdminColors.highlight} ${AdminColors.border} rounded focus:ring-blue-500`}
              required={required}
            />
            <label htmlFor={name} className={`text-lg font-medium ${AdminColors.primaryText}`}>{label}</label>
          </div>
        );
      default:
        return (
          <input
            type={type}
            id={name}
            name={name}
            value={String(value)}
            onChange={onChange}
            placeholder={placeholder}
            className={`w-full p-2 ${AdminColors.border} border rounded ${AdminColors.primaryText} bg-transparent focus:${AdminColors.focusBorder}`}
            required={required}
          />
        );
    }
  };

  return (
    <div className={`mb-4 ${className}`}>
      {label && (
        <label htmlFor={name} className={`block text-lg font-medium mb-2 text-sm ${AdminColors.secondaryText}`}>
          {label}
        </label>
      )}
      {renderInput()}
    </div>
  );
};

export default InputField;


