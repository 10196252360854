import React from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // AOS styles

// Importing service images
import FullServiceImg from '../../images/services/full-service.jpg';
import ElectricalRepairsImg from '../../images/services/electrical-repairs.jpg';
import ExhaustsImg from '../../images/services/exhausts.jpg';
import ClutchImg from '../../images/services/clutch.jpg';
import DiagnosticImg from '../../images/services/diagnostic.jpg';
import AirConditioningImg from '../../images/services/air-conditioning.jpg';
import SuspensionImg from '../../images/services/suspension.jpg';
import BrakesImg from '../../images/services/brakes.jpg';
import AccessoriesImg from '../../images/services/accessories.jpg';
import Class4CarImg from '../../images/services/class4-car.jpg';
import Class7VanImg from '../../images/services/class7-van.jpg';

// Service data
const services = [
  {
    title: "Full Service",
    description: "Complete vehicle inspection and maintenance checks to ensure optimal performance.",
    image: FullServiceImg,
  },
  {
    title: "Electrical Repairs",
    description: "Expert troubleshooting and repair of vehicle electrical systems.",
    image: ElectricalRepairsImg,
  },
  {
    title: "Exhausts",
    description: "Comprehensive exhaust system services including repair and replacement.",
    image: ExhaustsImg,
  },
  {
    title: "Clutch",
    description: "Clutch inspection, repair, and replacement for smooth gear transitions.",
    image: ClutchImg,
  },
  {
    title: "Diagnostic",
    description: "Advanced diagnostic tools to accurately identify and address electronic issues.",
    image: DiagnosticImg,
  },
  {
    title: "Air Conditioning",
    description: "Air conditioning service to ensure your ride is cool and comfortable.",
    image: AirConditioningImg,
  },
  {
    title: "Suspension",
    description: "Suspension checks and repairs to maintain ride comfort and handling.",
    image: SuspensionImg,
  },
  {
    title: "Brakes",
    description: "Safety-first brake services including pad replacement and fluid checks.",
    image: BrakesImg,
  },
  {
    title: "Accessories",
    description: "Wide range of vehicle accessories to enhance functionality and aesthetics.",
    image: AccessoriesImg,
  },
  {
    title: "Class 4 - CAR",
    description: "MOT testing for Class 4 vehicles, including cars, taxis, and small minibuses.",
    image: Class4CarImg,
  },
  {
    title: "Class 7 - VAN",
    description: "MOT testing for Class 7 vehicles, such as larger vans and commercial trucks.",
    image: Class7VanImg,
  },
];

const MotServices = () => {
  return (
    <div className="services-section bg-gray-900 py-16 px-6 lg:px-16">
      <div className="container mx-auto text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-gray-300 mb-4">Our Services</h2>
        <p className="text-gray-400 mb-10">
          Explore the wide range of services we offer to keep your vehicle running at its best.
        </p>

        {/* Services Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8" data-aos="fade-up">
          {services.map((service, index) => (
            <div
              key={index}
              className="service bg-gray-800 shadow-lg rounded-lg p-6 text-center hover:shadow-xl transition-shadow duration-300"
            >
              {/* Service Image */}
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-48 object-cover rounded mb-4"  // Adjusted class to fill width, added height, and object-cover
              />
              {/* Service Title */}
              <h3 className="text-xl font-semibold text-gray-300">
                {service.title}
              </h3>
              {/* Service Description */}
              <p className="text-gray-400">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MotServices;
