import React from 'react';
import AdminColors from './AdminColors';

interface ButtonProps {
  children: React.ReactNode; // The content inside the button (e.g., text or icon)
  onClick?: () => void; // Optional click handler
  type?: 'button' | 'submit' | 'reset'; // Button type
  disabled?: boolean; // Optional disabled state
  className?: string; // Additional custom classes
  size?: 'small' | 'medium' | 'large'; // Optional size prop
}

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  type = 'button',
  disabled = false,
  className = '',
  size = 'medium', // Default to medium if no size is specified
}) => {
  const sizeClasses = {
    small: 'px-4 py-2 text-sm',
    medium: 'px-6 py-2 text-base',
    large: 'px-6 py-3 text-lg',
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`
        rounded font-medium transition
        ${AdminColors.button} ${AdminColors.primaryText}
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-orange-800'}
        ${sizeClasses[size]} // Apply size-specific styles
        ${className}
      `}
    >
      {children}
    </button>
  );
};

export default Button;
