import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/AuthContext';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import { BACKEND_URL } from '../../../components/web/config';

interface MotQuery {
  id: number;
  name: string;
  email: string;
  phone: number;
  date: Date;
  package_id: number;
  class_type?: string | null;
  price?: string | null;
  status: string;
}

const MotQueries: React.FC = () => {
  const { user } = useAuth();
  const [motQueries, setMotQueries] = useState<MotQuery[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);


  useEffect(() => {
    const fetchMotQueries = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/mot-bookings/`);
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json();
        setMotQueries(data);
      } catch (error) {
        console.error('Error fetching MOT queries:', error);
      }
    };

    fetchMotQueries();
  }, []);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = motQueries.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(motQueries.length / recordsPerPage);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const updateStatus = async (id: number, newStatus: string) => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/mot-bookings/${id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: newStatus })
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      
      const updatedQuery = await response.json();
      setMotQueries(motQueries.map(mq => mq.id === id ? { ...mq, status: updatedQuery.motQuery.status } : mq));
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  return (
<AdminLayout>
  <h1 className="text-2xl font-bold">MOT Queries</h1>
  <div className="mt-4">
    {motQueries.length > 0 ? (
      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="py-3 px-6">Full Name</th>
              <th scope="col" className="py-3 px-6">Email</th>
              <th scope="col" className="py-3 px-6">Phone</th>
              <th scope="col" className="py-3 px-6">Package</th>
              <th scope="col" className="py-3 px-6">Class Type</th>
              <th scope="col" className="py-3 px-6">Price</th>
              <th scope="col" className="py-3 px-6">Preferred Date</th>
              <th scope="col" className="py-3 px-6">Status</th>
              <th scope="col" className="py-3 px-6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentRecords.map((query) => (
              <tr key={query.id} className="bg-white border-b dark:bg-slate-800 dark:border-gray-700">
                <td className="py-4 px-6">{query.name}</td>
                <td className="py-4 px-6">{query.email}</td>
                <td className="py-4 px-6">{query.phone}</td>
                <td className="py-4 px-6">{query.package_id}</td>
                <td className="py-4 px-6">{query.class_type || "N/A"}</td>
                <td className="py-4 px-6">{query.price || "N/A"}</td>
                <td className="py-4 px-6">{new Date(query.date).toLocaleDateString()}</td>
                <td className="py-4 px-6">{query.status}</td>
                <td className="py-4 px-6">
                  <button 
                    onClick={() => updateStatus(query.id, 'contacted')} 
                    className="text-indigo-500 hover:text-indigo-900 px-3 py-1"
                    disabled={query.status === 'completed'}
                  >
                    Contacted
                  </button>
                  <button 
                    onClick={() => updateStatus(query.id, 'completed')} 
                    className="text-green-600 hover:text-green-900 px-3 py-1"
                    disabled={query.status === 'completed'}
                  >
                    Completed
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex items-center justify-center space-x-2 mt-4">
              <button 
                onClick={() => handleChangePage(1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                First
              </button>
              <button 
                onClick={() => handleChangePage(currentPage - 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === 1 && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === 1}
              >
                Prev
              </button>
              <button 
                onClick={() => handleChangePage(currentPage + 1)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Next
              </button>
              <button 
                onClick={() => handleChangePage(totalPages)} 
                className={`px-3 py-1 text-sm text-white bg-slate-700 rounded hover:bg-orange-700 ${currentPage === totalPages && 'opacity-50 cursor-not-allowed'}`} 
                disabled={currentPage === totalPages}
              >
                Last
              </button>
          </div>
      </div>
    ) : (
      <p>No MOT queries found.</p>
    )}
  </div>
</AdminLayout>
  );
};

export default MotQueries;
