import React from 'react';
import AdminColors from './AdminColors';

interface CardProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  className?: string; // Allow custom styling
  size?: 'small' | 'medium' | 'large' | 'half' | 'full'; // Extend Card sizes with 'half' and 'full'
  position?: 'center' | 'left' | 'right'; // Position options
}

const Card: React.FC<CardProps> = ({
  children,
  title,
  subtitle,
  className = '',
  size = 'medium',
  position = 'center',
}) => {
  // If no content is provided, return null
  if (!children && !title && !subtitle) {
    return null;
  }

  // Define size classes with new 'half' and 'full' sizes
  const sizeClasses = {
    small: 'w-64 p-4', // Small size
    medium: 'w-96 p-6', // Medium size
    large: 'w-[42rem] p-8', // Large size
    half: 'w-1/2 p-6', // Half width of the screen
    full: 'w-full p-6', // Full width of the screen
  };

  // Define position classes
  const positionClasses = {
    center: 'mx-auto',
    left: 'ml-0 mr-auto',
    right: 'ml-auto mr-0',
  };

  return (
    <div
      className={`${sizeClasses[size]} ${positionClasses[position]} ${AdminColors.secondary} border ${AdminColors.border} ${className}`}
    >
      {title && <h2 className="text-lg font-bold mb-2">{title}</h2>}
      {subtitle && <p className={`text-sm mb-4 ${AdminColors.secondaryText}`}>{subtitle}</p>}
      {children}
    </div>
  );
};

export default Card;
