import React, { useEffect } from 'react';
import AdminColors from './AdminColors';

interface ModalProps {
  isOpen: boolean; // Control modal visibility
  title?: string; // Modal title
  children: React.ReactNode; // Content of the modal
  onClose: () => void; // Function to handle modal close
  className?: string; // Allow custom styling
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, children, onClose, className = '' }) => {
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isOpen) {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);

    return () => document.removeEventListener('keydown', handleEscape);
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50`}>
      <div
        className={`relative w-full max-w-md mx-4 p-6 rounded-lg ${AdminColors.secondary} ${AdminColors.primaryText} border ${AdminColors.border} ${className}`}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className={`absolute top-3 right-3 text-lg font-bold cursor-pointer hover:${AdminColors.highlight}`}
        >
          &times;
        </button>

        {/* Modal Title */}
        {title && <h2 className={`text-xl font-bold mb-4 ${AdminColors.primaryText}`}>{title}</h2>}

        {/* Modal Content */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
