import React, { useState, ChangeEvent, FormEvent } from 'react';
import InputField from './InputField';
import Button from './Button';

// Define the form data structure
interface FormData {
  title: string;
  firstname: string;
  surname: string;
  maritalStatus: string;
  drivingLicenseNo: string;
  noOfDependents: string;
  mobile: string;
  landline: string;
  email: string;
}

// Define the props for the FinanceForm component
interface FinanceFormProps {
    carId: number; // Add carId as a required prop
    onSubmit: (formData: FormData & { car_id: number }) => void;
  }
  
  const FinanceForm: React.FC<FinanceFormProps> = ({ carId, onSubmit }) => {
    const [formData, setFormData] = useState<FormData>({
      title: '',
      firstname: '',
      surname: '',
      maritalStatus: '',
      drivingLicenseNo: '',
      noOfDependents: '',
      mobile: '',
      landline: '',
      email: '',
    });

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit({ ...formData, car_id: carId }); // Include car_id in the submitted data
  };

  return (
    <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-slate-700 p-6 rounded-lg">
      <InputField
        type="text"
        name="title"
        value={formData.title}
        onChange={handleChange}
        placeholder="Title"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="text"
        name="firstname"
        value={formData.firstname}
        required
        onChange={handleChange}
        placeholder="First Name*"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="text"
        name="surname"
        value={formData.surname}
        required
        onChange={handleChange}
        placeholder="Surname*"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="text"
        name="maritalStatus"
        value={formData.maritalStatus}
        onChange={handleChange}
        placeholder="Marital Status"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="text"
        name="drivingLicenseNo"
        value={formData.drivingLicenseNo}
        onChange={handleChange}
        placeholder="Driving License No."
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="number"
        name="noOfDependents"
        value={formData.noOfDependents}
        onChange={handleChange}
        placeholder="No. of Dependents"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="tel"
        name="mobile"
        value={formData.mobile}
        required
        onChange={handleChange}
        placeholder="Mobile*"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="tel"
        name="landline"
        value={formData.landline}
        onChange={handleChange}
        placeholder="Landline"
        className='border border-slate-500 rounded bg-slate-800'
      />
      <InputField
        type="email"
        name="email"
        value={formData.email}
        required
        onChange={handleChange}
        placeholder="Email*"
        className='border border-slate-500 rounded bg-slate-800'
      />
        <div className="col-span-2 flex justify-start">
        <Button type="submit" size='small'>
          Submit
        </Button>
      </div>
    </form>
  );
};

export default FinanceForm;

export {};