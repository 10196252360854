import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaCar, FaTools, FaExchangeAlt, FaMoneyCheckAlt } from "react-icons/fa";

const services = [
  {
    icon: <FaCar className="text-orange-700 text-4xl" />,
    title: "Wide Selection of Cars",
    description:
      "Choose from a variety of cars, including SUVs, sedans, electric vehicles, and more.",
  },
  {
    icon: <FaTools className="text-orange-700 text-4xl" />,
    title: "Car Maintenance",
    description:
      "Our experts provide professional car servicing and maintenance for all models.",
  },
  {
    icon: <FaExchangeAlt className="text-orange-700 text-4xl" />,
    title: "Trade-In Options",
    description:
      "Upgrade your vehicle with easy trade-in options and competitive valuations.",
  },
  {
    icon: <FaMoneyCheckAlt className="text-orange-700 text-4xl" />,
    title: "Financing Assistance",
    description:
      "Flexible financing options to help you drive away in your dream car today.",
  },
];

const ServicesWeOffer = () => {
  return (
    <div className="services-section bg-gray-800 py-16 px-6 lg:px-16">
      <div className="container mx-auto text-center">
        {/* Section Title */}
        <h2 className="text-3xl font-bold text-gray-300 mb-4">Services We Offer</h2>
        <p className="text-gray-400 mb-10">
          Discover the wide range of services we provide to make your car buying
          experience seamless and enjoyable.
        </p>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8" data-aos="fade-up" >
          {services.map((service, index) => (
            <div
              key={index}
              className="service-card bg-gray-900 shadow-lg rounded-lg p-6 text-left hover:shadow-xl transition-shadow duration-300"
            >
              {/* Icon */}
              <div className="mb-4">{service.icon}</div>
              {/* Title */}
              <h3 className="text-xl font-semibold text-gray-400 mb-2">
                {service.title}
              </h3>
              {/* Description */}
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesWeOffer;
