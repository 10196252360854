import React from 'react';
import AdminColors, { AdminColorsType } from './AdminColors';

interface TableColumn {
  key: string;
  header: string;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  headerBgColor?: keyof AdminColorsType; 
  headerTextColor?: keyof AdminColorsType; 
  tableBgColor?: keyof AdminColorsType; 
  borderColor?: keyof AdminColorsType; 
  textColor?: keyof AdminColorsType; 
  className?: string;
}

const AdminTable: React.FC<TableProps> = ({
  columns,
  data,
  headerBgColor = 'primary', 
  headerTextColor = 'primaryText', 
  tableBgColor = 'tertiary', 
  borderColor = 'border', 
  textColor = 'primaryText', 
  className = '',
}) => {
  return (
    <div className={`overflow-x-auto shadow-md sm:rounded-lg ${className}`}>
      <table
        className={`w-full text-sm text-left ${AdminColors[tableBgColor]} ${AdminColors[textColor]}`}
      >
        <thead className={`${AdminColors[headerBgColor]} ${AdminColors[headerTextColor]}`}>
          <tr>
            {columns.map((column) => (
              <th key={column.key} scope="col" className="px-6 py-3">
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              className={`border-b ${AdminColors[borderColor]} hover:${AdminColors.secondary}`}
            >
              {columns.map((column) => (
                <td key={column.key} className="px-6 py-4">
                  {item[column.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
