import React, { useState, useEffect } from 'react';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import InputField from '../../../components/admin-panel/InputField';
import Card from '../../../components/admin-panel/Card';
import UploadFile from '../../../components/admin-panel/UploadFile';
import Button from '../../../components/admin-panel/Button';
import Modal from '../../../components/admin-panel/Modal'; // Import Modal component
import AdminColors from '../../../components/admin-panel/AdminColors';
import AdminTable from '../../../components/admin-panel/Table';
import { BACKEND_URL, STATIC_URL } from '../../../components/web/config';

interface Brand {
  id: number;
  name: string;
  imageUrl: string;
}

const CarBrand: React.FC = () => {
  const [brands, setBrands] = useState<Brand[]>([]);
  const [brandName, setBrandName] = useState('');
  const [brandImage, setBrandImage] = useState<File | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [brandToDelete, setBrandToDelete] = useState<Brand | null>(null);

  // Fetch all brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/brands/list`);
        const data = await response.json();

        // Prefix the image URL with the backend URL
        const updatedBrands = data.brands.map((brand: any) => ({
          id: brand.id,
          name: brand.name,
          imageUrl: `${BACKEND_URL}${brand.image_url}`,
        }));

        setBrands(updatedBrands);
      } catch (error) {
        console.error('Error fetching brands:', error);
        alert('Failed to fetch brands.');
      }
    };

    fetchBrands();
  }, []);

  const handleAddBrand = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!brandName || !brandImage) {
      alert('Please provide both the brand name and image.');
      return;
    }

    const formData = new FormData();
    formData.append('name', brandName);
    formData.append('image', brandImage);

    try {
      const response = await fetch(`${BACKEND_URL}/api/brands/create`, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        setBrands([
          ...brands,
          {
            id: data.brand.id,
            name: data.brand.name,
            imageUrl: `${STATIC_URL}${data.brand.image_url}`,
          },
        ]);
        setBrandName('');
        setBrandImage(null);
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Failed to add brand.');
      }
    } catch (error) {
      console.error('Error adding brand:', error);
      alert('Failed to add brand.');
    }
  };

  const handleDeleteBrand = async () => {
    if (!brandToDelete) return;

    try {
      const response = await fetch(`${BACKEND_URL}/api/brands/delete/${brandToDelete.id}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setBrands(brands.filter((brand) => brand.id !== brandToDelete.id));
        setIsModalOpen(false);
        setBrandToDelete(null);
      } else {
        const errorData = await response.json();
        alert(errorData.message || 'Failed to delete brand.');
      }
    } catch (error) {
      console.error('Error deleting brand:', error);
      alert('Failed to delete brand.');
    }
  };

  const confirmDeleteBrand = (brand: Brand) => {
    setBrandToDelete(brand);
    setIsModalOpen(true);
  };

    // Columns for AdminTable
    const columns = [
      { key: 'imageUrl', header: 'Image' },
      { key: 'name', header: 'Brand Name' },
      { key: 'actions', header: 'Actions' },
    ];

    const tableData = brands.map(brand => ({
      ...brand,
      imageUrl: <img src={brand.imageUrl} alt={brand.name} className="w-14 h-14 object-contain rounded" />,
      actions: (
        <Button size="small" onClick={() => confirmDeleteBrand(brand)} className="bg-red-600">
          Delete
        </Button>
      )
    }));

  return (
    <AdminLayout>
      <div className="flex justify-center">
        <Card title="Manage Car Brands">
          <form onSubmit={handleAddBrand} className="mb-6">
            <InputField
              type="text"
              name="brandName"
              value={brandName}
              placeholder="Enter brand name"
              onChange={(e) => setBrandName(e.target.value)}
              required
            />
            <UploadFile onFileUpload={(file) => setBrandImage(file as File)} multiple={false} />
            <Button type="submit" className="w-full px-4 py-2 font-medium rounded mt-4">
              Add Brand
            </Button>
          </form>
        </Card>
      </div>
      <div className="container mx-auto px-4">
          <h2 className={`text-xl font-bold mt-8 mb-4 ${AdminColors.primaryText}`}>Existing Brands</h2>
          <AdminTable
              columns={columns}
              data={tableData}
              headerBgColor="secondary"
              tableBgColor="tertiary"
              textColor="primaryText"
              className="mb-4"
            />
      </div>
      {/* Confirmation Modal */}
      <Modal
        isOpen={isModalOpen}
        title="Confirm Deletion"
        onClose={() => setIsModalOpen(false)}
      >
        <p className={`mb-4 text-sm ${AdminColors.primaryText}`}>
          Are you sure you want to delete{' '}
          <span className={`font-bold ${AdminColors.highlight}`}>
            {brandToDelete?.name}
          </span>
          ?
        </p>
        <div className="flex justify-end">
          <Button
            size='small'
            onClick={() => setIsModalOpen(false)}
            className={`bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 ${AdminColors.primary} ${AdminColors.primaryText} rounded`}
          >
            Cancel
          </Button>
          <Button
            size='small'
            onClick={handleDeleteBrand}
            className={`ml-3 px-4 py-2 bg-red-600 rounded`}
          >
            Confirm
          </Button>
        </div>
      </Modal>
    </AdminLayout>
  );
};

export default CarBrand;
