import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import AdminColors from '../../../components/admin-panel/AdminColors';
import AdminTable from '../../../components/admin-panel/Table';
import Card from '../../../components/admin-panel/Card';
import InputField from '../../../components/admin-panel/InputField';
import Button from '../../../components/admin-panel/Button';
import { useAuth } from '../../hooks/AuthContext';
import Notification from '../../../components/admin-panel/Notification';
import Modal from '../../../components/admin-panel/Modal';
import { BACKEND_URL, STATIC_URL } from '../../../components/web/config';

interface Car {
  id: number;
  registration_number: string;
  make: string;
  year: number;
  color: string;
  fuelType: string;
  taxStatus: string;
  engineCapacity: string;
  co2Emissions: string;
  markedForExport: boolean;
  typeApproval: string;
  wheelPlan: string;
  revenueWeight: number;
  realDrivingEmissions: string;
  dateOfLastV5CIssued: Date;
  automatedVehicle: boolean;
  price: number;
  mileage: number;
  isFeatured: boolean;
  category: string;
  status: string;
  description?: string;
  submitted_by: number;
  listed: boolean;
  images: string[];
}

// Define the notification state with a strict type
interface NotificationState {
  show: boolean;
  message: string;
  type: 'success' | 'error';
}

const CarDetail: React.FC = () => {
  const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [car, setCar] = useState<Car | null>(null);
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({ show: false, message: '', type: 'error' });
  const [error, setError] = useState('');
  const [price, setPrice] = useState('');
  const [mileage, setMileage] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');
  const [listed, setListed] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false); 
  const [status, setStatus] = useState('unsold');
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    fetchCarDetails();
  }, [id]);

  const fetchCarDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details/${id}`);
      if (response.ok) {
        const data = await response.json();
        setCar({
          ...data.car,
          fuelType: data.car.fuel_type,
          isFeatured: data.car.isFeatured ?? false,
          taxStatus: data.car.tax_status,
          engineCapacity: data.car.engine_capacity,
          co2Emissions: data.car.co2_emissions,
          markedForExport: data.car.marked_for_export,
          typeApproval: data.car.type_approval,
          wheelPlan: data.car.wheelplan,
          revenueWeight: data.car.revenue_weight,
          realDrivingEmissions: data.car.real_driving_emissions,
          dateOfLastV5CIssued: data.car.date_of_last_v5c_issued,
          automatedVehicle: data.car.automated_vehicle,
        });
        // Set other state variables
        setPrice(data.car.price.toLocaleString());
        setMileage(data.car.mileage.toString());
        setCategory(data.car.category || 'Unknown');
        setDescription(data.car.description || '');
        setIsFeatured(data.car.is_featured ?? false);
        setListed(data.car.listed);
        setStatus(data.car.status);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch car details.');
      }
    } catch (err) {
      setError('An error occurred while fetching car details.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCarDetails = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/details/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          price: parseFloat(price.replace(/,/g, '')),
          mileage: parseInt(mileage, 10),
          category,
          description,
          listed,
          is_featured: isFeatured,
          status
        })
      });

      if (response.ok) {
        setNotification({ show: true, message: 'Car details updated successfully!', type: 'success' });
        fetchCarDetails(); // Refresh the details
      } else {
        const errorData = await response.json();
        setNotification({ show: true, message: errorData.message || 'Failed to update car details.', type: 'error' });
      }
    } catch (error) {
      console.error('Error updating car details:', error);
      alert('Failed to update car details.');
    }
  };

  // Function for deleting a car
  const handleDeleteCar = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/sell-cars/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        navigate('/sell-car/view'); // Navigate back to the dashboard on success
      } else {
        throw new Error('Failed to delete the car.');
      }
    } catch (error) {
      console.error('Error deleting car:', error);
    }
  };


  if (loading) return <AdminLayout><p className="text-blue-500 mt-4">Loading car details...</p></AdminLayout>;
  if (error) return <AdminLayout><p className="text-red-500 mt-4">{error}</p></AdminLayout>;
  if (!car) return <AdminLayout><p className="text-red-500 mt-4">No car details available.</p></AdminLayout>;

  const sliderSettings = {
    dots: true,
    infinite: car.images.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const carCategories = [
    { label: "Sedan", value: "Sedan" },
    { label: "SUV", value: "SUV" },
    { label: "Hatchback", value: "Hatchback" },
    { label: "Coupe", value: "Coupe" },
    { label: "Convertible", value: "Convertible" },
    { label: "Pickup Truck", value: "Pickup Truck" },
    { label: "Minivan", value: "Minivan" },
  ];

return (
<AdminLayout>
  {loading && <p className="text-gray-500 mt-4">Loading car details...</p>}
  {notification.show && <Notification message={notification.message} type={notification.type} />}
  {!loading && car && (
    <div className="container mx-auto px-4 mt-3">
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4 mb-8">
        {/* Car Images */}
        <div className="md:col-span-2">
          <h1 className="text-2xl font-bold mb-4">{car.make || 'Unknown Make'} Pictures</h1>
          {car.images && car.images.length > 0 ? (
            <Slider {...sliderSettings}>
              {car.images.map((image, index) => (
                <div key={index} className="h-96 flex justify-center items-center">
                  <img
                    src={`${STATIC_URL}${image}`}
                    alt={`Car Image ${index + 1}`}
                    className="w-full h-full object-cover rounded"
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <p className={`text-sm ${AdminColors.secondaryText}`}>
              No images available for this car.
            </p>
          )}
        </div>

        {/* Basic Car Details */}
        <div className="md:col-span-3">
          <h1 className="text-2xl font-bold mb-4">Basic Car Details</h1>
          <AdminTable
            columns={[{ key: 'field', header: 'Field' }, { key: 'value', header: 'Value' }]}
            data={[
              { field: 'Registration Number', value: car.registration_number },
              { field: 'Make', value: car.make },
              { field: 'Year', value: car.year },
              { field: 'Color', value: car.color },
              { field: 'Price', value: `$${price}` },
              { field: 'Mileage', value: `${mileage} miles` },
              { field: 'Status', value: car.status },
              { field: 'Submitted By', value: user?.name || 'Admin' }
            ]}
            headerBgColor="secondary"
            tableBgColor="tertiary"
            borderColor="border"
            textColor="primaryText"
            className="mb-4"
          />
        </div>
      </div>

      {/* Technical Specifications */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Technical Specifications</h2>
        <AdminTable
          columns={[{ key: 'field', header: 'Field' }, { key: 'value', header: 'Value' }]}
          data={[
            { field: 'Fuel Type', value: car.fuelType },
            { field: 'Engine Capacity', value: `${car.engineCapacity} cc` },
            { field: 'CO2 Emissions', value: `${car.co2Emissions} g/km` },
            { field: 'Wheel Plan', value: car.wheelPlan },
            { field: 'Revenue Weight', value: `${car.revenueWeight} kg` }
          ]}
          headerBgColor="secondary"
          tableBgColor="tertiary"
          borderColor="border"
          textColor="primaryText"
        />
      </div>

      {/* Regulatory Information */}
      <div className="mb-8">
        <h2 className="text-xl font-bold mb-4">Regulatory Information</h2>
        <AdminTable
          columns={[{ key: 'field', header: 'Field' }, { key: 'value', header: 'Value' }]}
          data={[
            { field: 'Tax Status', value: car.taxStatus },
            { field: 'Marked for Export', value: car.markedForExport ? 'Yes' : 'No' },
            { field: 'Type Approval', value: car.typeApproval },
            { field: 'Date of Last V5C Issued', value: car.dateOfLastV5CIssued },
            { field: 'Automated Vehicle', value: car.automatedVehicle ? 'Yes' : 'No' }
          ]}
          headerBgColor="secondary"
          tableBgColor="tertiary"
          borderColor="border"
          textColor="primaryText"
        />
      </div>

      {/* Edit Car Details Form */}
      <Card title="Edit Car Details" size='full'>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <InputField label="Price" type="text" name="price" value={price} onChange={(e) => setPrice(e.target.value)} />
          <InputField label="Mileage" type="text" name="mileage" value={mileage} onChange={(e) => setMileage(e.target.value)} />
          <InputField label="Listed" type="select" name="listed" value={listed.toString()} options={[{ value: "true", label: 'Yes' }, { value: "false", label: 'No' }]} onChange={(e) => setListed(e.target.value === 'true')} />
          <InputField label="Status" type="select" name="status" value={status} options={[{ value: 'sold', label: 'Sold' }, { value: 'unsold', label: 'Unsold' }]} onChange={(e) => setStatus(e.target.value)} />
          <InputField label="Category" type="select" name="category" value={category} options={carCategories} onChange={(e) => setCategory(e.target.value)} className="mb-4" />
          <InputField
      label="Feature"
      type="select"
      name="isFeatured"
      value={isFeatured.toString()} // Ensure conversion to string
      options={[{ value: "true", label: 'Yes' }, { value: "false", label: 'No' }]}
      onChange={(e) => setIsFeatured(e.target.value === 'true')}
      className="mb-4"
    />
        </div>
          <InputField label="Description" type="textarea" name="description" value={description} onChange={(e) => setDescription(e.target.value)} className="w-full" />
          <Button size="small" onClick={handleUpdateCarDetails} className="mt-4 mr-3">Update Details</Button>
          <Button size='small' onClick={() => setModalOpen(true)} className="bg-red-600 hover:bg-red-700 text-white">
            Delete Car
          </Button>
      </Card>
      </div>
    )}
<Modal
  isOpen={isModalOpen}
  onClose={() => setModalOpen(false)}
  title="Confirm Deletion"
>
  <div className="text-center p-4">
    <p className="mb-4">Are you sure you want to delete this car?</p>
    <div className="flex justify-end">
      <Button size='small' onClick={handleDeleteCar} className="bg-red-500 hover:bg-red-700 text-white px-5 py-2 rounded">
        Confirm Delete
      </Button>
      <Button size='small' onClick={() => setModalOpen(false)} className="bg-gray-300 hover:bg-gray-400 text-black px-5 ml-2 py-2 rounded">
        Cancel
      </Button>
    </div>
  </div>
</Modal>

</AdminLayout>
);

};

export default CarDetail;
