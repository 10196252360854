import React from 'react';
import AdminColors from './AdminColors';  // Assuming AdminColors is properly exported

const Footer: React.FC = () => {
  return (
    <footer className={`${AdminColors.secondary} text-white text-center p-5 text-sm`}>
      <p>© {new Date().getFullYear()} <span className="text-orange-400">Dragon Autos</span>. All Rights Reserved.</p>
      <p className='text-gray-300 mt-1'>Developed by <a href='https://vravenz.com' target='_blank'>vravenz</a></p>
    </footer>
  );
};

export default Footer;
