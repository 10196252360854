import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from 'react-router-dom';
import { FaCar, FaCalendarAlt, FaTachometerAlt, FaGasPump, FaPoundSign, FaDollarSign } from 'react-icons/fa';
import { IoSearchSharp } from "react-icons/io5";
import { PiEngineFill } from "react-icons/pi";
import { TbCategoryFilled } from "react-icons/tb";
import AdminColors from "../admin-panel/AdminColors";
import Button from "../admin-panel/Button";
import { BACKEND_URL, STATIC_URL } from './config';

// Define a type for the car objects
interface Car {
  id: number;
  registration_number: string;
  make: string;
  model: string;
  price: number;
  image_url: string;
  year: number;
  mileage: number;
  fuel_type: string;
  category: string;
  engine_capacity: number;
  status: string;
}

const FeaturedCarsSection = () => {
  const [featuredCars, setFeaturedCars] = useState<Car[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFeaturedCars = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/cars/featured-cars`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const cars: Car[] = await response.json();
  
        // Remove duplicates based on the unique `id`
        const uniqueCars = Array.from(new Map(cars.map(car => [car.id, car])).values());
        setFeaturedCars(uniqueCars);
      } catch (error) {
        console.error('Error fetching featured cars:', error);
      }
    };
  
    fetchFeaturedCars();
  }, []);
  

  const handleCarClick = (carId: number) => {
    navigate(`/cars/${carId}`); 
  };

  const settings = {
    dots: false,
    infinite: featuredCars.length > 1,
    speed: 500,
    slidesToShow: Math.min(3, featuredCars.length),
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(2, featuredCars.length),
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(1, featuredCars.length),
        },
      },
    ],
  };

  return (
    <div className="featured-cars-section bg-gray-900 text-white py-10 px-6 lg:px-16">
      <h2 className="text-3xl font-bold mb-2 text-left">Featured Cars</h2>
      <p className="text-gray-400 text-left mb-6">
        Explore our collection of top-notch cars, handpicked for their performance and style.
      </p>
      {featuredCars.length > 0 ? (
        <Slider {...settings}>
        {featuredCars.map((car, index) => (
          <div key={index} className="p-4" data-aos="zoom-in">
            <div className="bg-gray-800 rounded-lg overflow-hidden shadow-lg">
              <img src={`${STATIC_URL}${car.image_url}`} alt={car.make} className="w-full h-56 object-cover" />
              <div className="p-4">
              <div className="flex justify-between items-center border-b border-gray-700 pb-2 mb-2">
                <h2 className="text-xl text-slate-300 font-bold">{car.make || 'Unknown Make'}</h2>
                <div className="flex items-center bg-slate-400 px-3 rounded">
                    <FaPoundSign className="text-xl text-slate-800" />
                    <span className="text-2xl text-slate-800 rounded font-bold">{car.price}</span>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-4 text-sm text-slate-300">
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaCar className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.registration_number}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaCalendarAlt className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.year}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <TbCategoryFilled className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.category}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaTachometerAlt className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.mileage} miles</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <FaGasPump className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.fuel_type || 'N/A'}</span>
                </div>
                <div className="flex items-center py-2 border-b border-gray-700 col-span-1">
                    <PiEngineFill className="mr-2 text-lg" />
                    <span className='text-slate-400'>{car.engine_capacity || 'N/A'}</span>
                </div>
            </div>
            <div className="flex gap-2 mb-2">
                <Button
                    className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center bg-sky-700 hover:bg-sky-800`}
                    onClick={() => handleCarClick(car.id)}
                >
                    <IoSearchSharp className="mr-1" />View
                </Button>
                <Button
                    className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center bg-teal-700 hover:bg-teal-800`}
                    onClick={() => navigate(`/testdrive/${car.id}`)}
                >
                    <FaCar className="mr-1" />Test Drive
                </Button>
            </div>
            <Button
                size="small"
                className={`${AdminColors.button} ${AdminColors.primaryText} w-full flex justify-center items-center border border-slate-400 bg-transparent hover:bg-slate-600`}
                onClick={() => navigate(`/finance/${car.id}`)}
            >
                <FaDollarSign className="mr-1" />Finance
            </Button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      ) : (
        <p className="text-center text-gray-400">No featured cars available.</p>
      )}
    </div>
  );
};

export default FeaturedCarsSection;
