import React from 'react';

interface NotificationProps {
  message: string;
  type: 'error' | 'success'; // Type of notification
}

const Notification: React.FC<NotificationProps> = ({ message, type }) => {
  const backgroundColor = type === 'success' ? 'bg-green-700' : 'bg-red-700';
  return (
    <div className={`${backgroundColor} text-white px-4 py-2 rounded relative max-w-md mx-auto`} role="alert">
      <span className="block sm:inline">{message}</span>
    </div>
  );
};

export default Notification;
