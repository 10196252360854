import React from 'react';
import NavBar from './NavBar';
import AdminColors from './AdminColors';
import Footer from './footer';

const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className={`flex flex-col ${AdminColors.primary} ${AdminColors.primaryText} min-h-screen`}>
      <NavBar />
      <main className="flex-grow p-6">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default AdminLayout;
