import React, { useState } from 'react';
import AdminColors from './AdminColors';

interface UploadFileProps {
  onFileUpload: (files: File | File[]) => void;
  multiple?: boolean; // Enable multiple file selection
}

const UploadFile: React.FC<UploadFileProps> = ({ onFileUpload, multiple = false }) => {
  const [previews, setPreviews] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const filePreviews = files.map((file) => URL.createObjectURL(file));
      setPreviews(filePreviews);

      // Pass either a single file or multiple files
      onFileUpload(multiple ? files : files[0]);
    }
  };

  return (
    <div className="flex flex-col items-center">
      <label
        htmlFor="fileUpload"
        className={`flex flex-col items-center justify-center w-full h-64 border-2 border-dashed ${AdminColors.border} rounded cursor-pointer hover:border-gray-500 transition`}
      >
        {previews.length === 0 ? (
          <div className="flex flex-col items-center">
            <div
              className={`w-12 h-12 flex items-center justify-center rounded-full bg-gray-100 text-gray-500 text-3xl`}
            >
              +
            </div>
            <p className={`text-sm mt-4 ${AdminColors.secondaryText}`}>
              Click here to upload {multiple ? 'images' : 'an image'}
            </p>
          </div>
        ) : previews.length === 1 ? (
          // Single image preview - Full width
          <img
            src={previews[0]}
            alt="Single Preview"
            className="w-full h-full object-cover rounded"
          />
        ) : (
          // Multiple images - Grid layout
          <div className="grid grid-cols-3 gap-2">
            {previews.map((preview, index) => (
              <img
                key={index}
                src={preview}
                alt={`Preview ${index}`}
                className="object-cover w-16 h-16 rounded"
              />
            ))}
          </div>
        )}
      </label>
      <input
        id="fileUpload"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        multiple={multiple} // Enable multiple selection if `multiple` is true
        className="hidden"
      />
    </div>
  );
};

export default UploadFile;
