import React, { useState } from 'react';
import { FiPower } from 'react-icons/fi';
import AdminColors from './AdminColors';
import Logo from '../../images/logo.png';
import Modal from './Modal';
import Button from './Button';

const NavBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSellCarDropdownOpen, setSellCarDropdownOpen] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false); // State to control the logout modal

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleSellCarDropdown = () => {
    setSellCarDropdownOpen(!isSellCarDropdownOpen);
  };

  const handleLogoutConfirm = () => {
    localStorage.removeItem('auth');
    window.location.href = '/login';
  };


  return (
    <nav className={`flex justify-between items-center px-6 py-4 ${AdminColors.secondary} ${AdminColors.primaryText} shadow-lg relative z-50`} >
      {/* Left side - Logo */}
      <div className="flex items-center">
        <a href="/dashboard">
          <img src={Logo} width={80} alt="Logo" />
        </a>
      </div>

      {/* Right side - Hamburger Menu */}
      <button
        onClick={toggleMenu}
        className="block md:hidden focus:outline-none"
      >
        <svg
          className="w-6 h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </button>

      {/* Links - Hidden on Small Screens */}
      <ul
        className={`${
          isOpen ? 'block' : 'hidden'
        } md:flex md:space-x-8 md:items-center absolute md:static top-16 left-0 w-full md:w-auto bg-gray-800 text-slate-300 md:bg-transparent p-4 md:p-0 z-50`}
      >
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/dashboard" className="text-md font-medium">
            Dashboard
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/car-brands" className="text-md font-medium">
            Car Brands
          </a>
        </li>
        <li className="relative group">
          <button
            onClick={toggleSellCarDropdown}
            className="text-md font-medium hover:text-orange-500 transition duration-200"
          >
            Sell Car
          </button>
          {isSellCarDropdownOpen && (
            <ul
              className={`absolute top-10 w-36 left-0 bg-gray-800 border ${AdminColors.border} rounded-md shadow-lg z-50`}
            >
              <li className="hover:bg-orange-700">
                <a
                  href="/sell-car/add"
                  className="block px-4 py-2 text-sm font-medium text-white"
                >
                  Add
                </a>
              </li>
              <li className="hover:bg-orange-700">
                <a
                  href="/sell-car/view"
                  className="block px-4 py-2 text-sm font-medium text-white"
                >
                  View
                </a>
              </li>
            </ul>
          )}
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/finance-queries" className="text-md font-medium">
            Finance Queries
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/test-drive-queries" className="text-md font-medium">
            Test Drive Queries
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/mot-queries" className="text-md font-medium">
            MOT
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/inquiries" className="text-md font-medium">
          Inquiries
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/subscriber" className="text-md font-medium">
            Subscriber
          </a>
        </li>
        <li className="hover:text-orange-500 transition duration-200">
          <a href="/settings" className="text-md font-medium">
            Profile
          </a>
        </li>
        {/* Logout Button */}
        <li className="ml-4">
          <button
            onClick={() => setLogoutModalOpen(true)}
            className={`flex items-center space-x-2 p-2 bg-orange-700 hover:bg-orange-800 text-white rounded transition duration-200`}
          >
            <FiPower className="w-4 h-4" />
          </button>
        </li>
      </ul>
      <Modal
        isOpen={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        title="Confirm Logout"
      >
        <div className="text-left">
          <p>Are you sure you want to log out?</p>
          <div className="mt-4 flex justify-end">
            <Button
              size='small'
              onClick={handleLogoutConfirm}
              className="bg-red-500 hover:bg-red-700 mr-2 text-white px-4 py-2 rounded"
            >
              Logout
            </Button>
            <button
     
              onClick={() => setLogoutModalOpen(false)}
              className="bg-gray-300 hover:bg-gray-400 text-black px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </nav>
  );
};

export default NavBar;
