import React from 'react';
import { useAuth } from '../../hooks/AuthContext';
import AdminLayout from '../../../components/admin-panel/AdminLayout';
import Card from '../../../components/admin-panel/Card';
import AdminTable from '../../../components/admin-panel/Table';

const Settings: React.FC = () => {
  const { user } = useAuth();

  const tableColumns = [
    { key: 'field', header: 'Field' },
    { key: 'value', header: 'Value' }
  ];

  const userData = user ? [
    { field: 'Name', value: user.name },
    { field: 'Email', value: user.email },
    { field: 'Role', value: user.role },
  ] : [];

  return (
    <AdminLayout>
      <div className="flex justify-center">
        <Card title="User Profile" subtitle="Manage your Profile" size="medium" position="center">
          {user ? (
            <AdminTable
              columns={tableColumns}
              data={userData}
              headerBgColor="primary" // Uses the secondary background color for headers
              headerTextColor="primaryText" // Text color for headers
              tableBgColor="tertiary" // Background color for the table body
              borderColor="border" // Border color for rows
              textColor="primaryText" // Text color for table data
            />
          ) : (
            <p className="text-center">No user data available.</p>
          )}
        </Card>
      </div>
    </AdminLayout>
  );
};

export default Settings;
